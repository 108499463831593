import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const SubnavWrapper = styled.div`
  position: relative;
  padding: 10px 0px;
  background-color: #f3f3f3;

  @media print {
    display: none !important;
  }
`;

const SubnavContainer = styled.div`
  max-width: 1403px;
  padding: 0px 60px;
  margin: auto;
  text-align: center;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    padding: 0px 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 0px 20px;
  }
`;

const SubnavLink = styled(Link)`
  display: inline-block;
  padding: 10px 10px;
  font-size: ${props => props.theme.size.medium};

  &.active {
    color:  ${props => props.theme.colours.green};
  }
`;

export default function SlideshowComponent({
  data
}) {
  const {
    links
  } = data;

  return (
    <SubnavWrapper>
      <SubnavContainer>
        {links.map((link) => (
          <SubnavLink
            to={link.linkUrl}
            activeClassName="active"
            target={link.openInNewTab ? '_blank' : '_self'}
            rel="noreferrer"
          >
            {link.linkText}
          </SubnavLink>
        ))}
      </SubnavContainer>
    </SubnavWrapper>
  );
}

SlideshowComponent.propTypes = {
  data: PropTypes.shape({
    links: PropTypes.arrayOf({
      linkUrl: PropTypes.string,
      linkText: PropTypes.string,
      openNewTab: PropTypes.bool
    })
  })
};
